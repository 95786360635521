import { Table, TablePaginationConfig } from "antd";
import { GetRowKey, TableRowSelection } from "antd/lib/table/interface";
import { CSSProperties, ReactNode } from "react";
import { CSVLink } from "react-csv";
import { safeColumns } from "../Helpers/safeColumns";
import Spacer from "../Spacer";
import HorizontalStack from "./HorizontalStack";
import Stack from "./Stack";

export interface DataTableColumn<T> {
  key?: string;
  show?: boolean;
  title: ReactNode;
  render: (item: T, index: number) => ReactNode;
  sorter?: (a: T, b: T) => number;
  width?: number;
  fixed?: "left" | "right";
}

export interface DataTableCsvColumn<T> {
  title: string;
  render: (item: T, index: number) => ReactNode;
}

export interface DataTableProps<T> {
  data: Array<T>;
  columns: Array<DataTableColumn<T>>;
  pagination?: false | TablePaginationConfig;
  csvColumns?: DataTableCsvColumn<T>[];
  rowKey?: string | GetRowKey<T>;
  rowSelection?: TableRowSelection<T>;
  scroll?: { x: string };
  style?: CSSProperties;
}

type CsvRow = { [key: string]: any };

export function DataTable<T extends object>(props: DataTableProps<T>) {
  const transformCsvData = (
    data: Array<T>,
    columns: DataTableCsvColumn<T>[]
  ) => {
    return data.map((row) => {
      const newRow: CsvRow = {};
      columns.forEach((column, index) => {
        newRow[column.title] = column.render(row, index);
      });
      return newRow;
    });
  };

  return (
    <Stack align="left" width="100%">
      <Table
        style={{ width: "100%", ...props.style }}
        pagination={props.pagination}
        columns={safeColumns<T>(
          props.columns
            .filter((c) => (c.show === undefined ? true : c.show))
            .map(function (col) {
              return {
                title: col.title,
                dataIndex: "",
                key: col.title as string,
                render: (ignore, item, index) => col.render(item, index),
                sorter: col.sorter,
                defaultSortOrder: "descend",
                sortDirections: ["descend", "ascend", "descend"],
                width: col.width,
                fixed: col.fixed,
              };
            })
        )}
        dataSource={props.data}
        rowKey={props.rowKey}
        rowSelection={props.rowSelection}
        scroll={props.scroll}
      />

      {props.csvColumns && (
        <HorizontalStack>
          <Spacer width={32} />
          <CSVLink
            data={transformCsvData(props.data, props.csvColumns)}
            headers={props.csvColumns.map((column) => ({
              label: column.title,
              key: column.title,
            }))}
          >
            Download CSV
          </CSVLink>
        </HorizontalStack>
      )}
    </Stack>
  );
}
