import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import {
  CountryCode,
  ShipmentReport,
  ShipmentReportQuoteSummary,
} from "../generated-openapi-client";

interface DownloadCarrierComparisonButtonProps {
  quotes: ShipmentReport[];
  carrierIdentifier: string | undefined;
}

interface DownloadCarrierComparisonLinkProps {
  quotes: ShipmentReport[];
  carrierIdentifier: string | undefined;
  includeRankedNumber1: boolean;
  includeLowestRankCarrierIdentifier: boolean;
}

export function DownloadCarrierComparisonLink(
  props: DownloadCarrierComparisonLinkProps
) {
  if (props.carrierIdentifier === undefined) {
    return <></>;
  }

  const data = props.quotes.map(function (sr) {
    function carrierPriceFromQuote(srqs: ShipmentReportQuoteSummary): number {
      return srqs.quotedPriceByCarrierWithoutTaxCad!!;
    }

    function rateFromQuote(
      srqs: ShipmentReportQuoteSummary | undefined
    ): string {
      if (srqs === undefined) {
        return "-";
      } else {
        return carrierPriceFromQuote(srqs).toFixed(2);
      }
    }

    function rank(): string {
      const index = sr.quotes!!.findIndex(
        (srqs) => srqs.carrierIdentifier === props.carrierIdentifier
      );
      if (index === undefined) {
        return "-";
      }

      return (index + 1).toString();
    }

    function delta(
      lowest: ShipmentReportQuoteSummary | undefined,
      carrierQuote: ShipmentReportQuoteSummary | undefined
    ) {
      if (lowest === undefined || carrierQuote === undefined) {
        return "-";
      }

      return (
        "$" +
        (
          carrierPriceFromQuote(carrierQuote) - carrierPriceFromQuote(lowest)
        ).toFixed(2)
      );
    }

    function percentage(
      lowest: ShipmentReportQuoteSummary | undefined,
      carrierQuote: ShipmentReportQuoteSummary | undefined
    ) {
      if (lowest === undefined || carrierQuote === undefined) {
        return "-";
      }

      return (
        (
          100 *
            (carrierPriceFromQuote(carrierQuote) /
              carrierPriceFromQuote(lowest)) -
          100
        ).toFixed(0) + "%"
      );
    }

    const o = {} as {
      [key: string]: string | undefined | CountryCode | number;
    };

    const carrierQuote = sr.quotes!!.find(
      (srqs) => srqs.carrierIdentifier === props.carrierIdentifier
    );
    o["Pickup City"] = sr.pickupCity;
    o["Pickup State"] = sr.pickupState;
    o["Pickup Country"] = sr.pickupCountry;
    o["Delivery City"] = sr.deliveryCity;
    o["Delivery State"] = sr.deliveryState;
    o["Delivery Country"] = sr.deliveryCountry;
    o["⭐ Lowest Rate"] = "$" + rateFromQuote(sr.lowestQuote);
    if (props.includeLowestRankCarrierIdentifier) {
      o["⭐ Lowest Rate Carrier"] = sr.lowestQuote?.carrierIdentifier;
    }

    o[`🚚 ${props.carrierIdentifier} Rate`] = "$" + rateFromQuote(carrierQuote);
    o[`🚚 ${props.carrierIdentifier} Rank`] = rank();
    o[`🚚 ${props.carrierIdentifier} Delta`] = delta(
      sr.lowestQuote,
      carrierQuote
    );
    o[`🚚 ${props.carrierIdentifier} Percentage Off`] = percentage(
      sr.lowestQuote,
      carrierQuote
    );

    o["Number Skids"] = sr.totalHandlingUnits;
    o["Total Weight (lb)"] = sr.totalWeight;
    o["Total Volume (cuft)"] = sr.totalVolume;
    o["Density"] = sr.density?.toFixed(1);
    o["Freight Class"] = sr.freightClass;
    o["Residential Pickup"] = sr.needsResidentialPickup ? "Yes" : "-";
    o["Residential Delivery"] = sr.needsResidentialDelivery ? "Yes" : "-";
    o["Liftgate Pickup"] = sr.needsLiftgatePickup ? "Yes" : "-";
    o["Liftgate Delivery"] = sr.needsLiftgateDelivery ? "Yes" : "-";
    o["Inside Pickup"] = sr.needsInsidePickup ? "Yes" : "-";
    o["Inside Delivery"] = sr.needsInsideDelivery ? "Yes" : "-";
    o["FSREF"] = sr.billOfLadingNumber;

    return o;
  });

  if (data.length === 0) {
    // No data so no comparison possible
    return <></>;
  }

  const columns = Object.keys(data[0]);

  const filename = `${props.carrierIdentifier} Rates Comparison.csv`;
  console.log(`csv comparison filename = ${filename}`);

  return (
    <CSVLink
      data={data.filter(function (o) {
        if (props.includeRankedNumber1) {
          return true;
        } else {
          return o[`🚚 ${props.carrierIdentifier} Rank`] !== "1";
        }
      })}
      filename={filename}
      headers={columns}
    >
      Download Carrier Comparison CSV
    </CSVLink>
  );
}

import { Button, Form, Modal, Switch } from "antd";
import { useState } from "react";

export function DownloadCarrierComparisonButton(
  props: DownloadCarrierComparisonButtonProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [includeRankedNumber1, setIncludeRankedNumber1] = useState(false);
  const [
    includeLowestRankCarrierIdentifier,
    setIncludeLowestRankCarrierIdentifier,
  ] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Carrier Comparison CSV"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
        footer={<></>}
      >
        <Form.Item label="Include Quotes where carrier was ranked #1?">
          <Switch
            checked={includeRankedNumber1}
            onChange={setIncludeRankedNumber1}
          />
        </Form.Item>
        <Form.Item label="Include Best Rank Carrier Identifier?">
          <Switch
            checked={includeLowestRankCarrierIdentifier}
            onChange={setIncludeLowestRankCarrierIdentifier}
          />
        </Form.Item>
        <DownloadCarrierComparisonLink
          {...props}
          includeRankedNumber1={includeRankedNumber1}
          includeLowestRankCarrierIdentifier={
            includeLowestRankCarrierIdentifier
          }
        />
      </Modal>
      <Button icon={<DownloadOutlined />} onClick={showModal}>
        Download Carrier Comparison CSV
      </Button>
    </>
  );
}
