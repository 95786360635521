// This is a generic component we can use to quickly build

import {
  Button,
  Form,
  Menu,
  MenuItemProps,
  message,
  Modal,
  Tooltip,
  Typography,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import { Loading } from "../../Components/Loading";
import Stack from "../../Components/Stack";
import SupportAgents from "../../Fixtures/SupportAgents.json";
import {
  EmailDescription,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { ShipmentDocument } from "../../generated-openapi-client/models/ShipmentDocument";
import Spacer from "../../Spacer";
import { SupportAgent } from "../ViewCompanyScreen";
import { ManageAttachmentsButton } from "../ViewCompanyScreenComponents/ManageAttachmentsButton";
import { SupportAgentDropdown } from "./SupportAgentDropdown";

const { Text } = Typography;

interface SupportEmailBodyExtraItemProps {
  label: string;
  children: ReactNode;
}

export function SupportEmailBodyExtraItem(
  props: SupportEmailBodyExtraItemProps
) {
  return (
    <HorizontalStack>
      <Text>{props.label}</Text>
      <Spacer width={16} />
      {props.children}
    </HorizontalStack>
  );
}

interface SupportEmailButtonProps {
  disabled?: boolean;
  tooltip?: string;
  label: string;
  onCancel?: () => void;
  onFetchPreview: (
    supportAgent: SupportAgent
  ) => Promise<EmailDescription | undefined>;
  onSend: (
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ) => Promise<boolean>;
  previewUpdateHash: string;
  extraFormItems?: ReactNode;
  shipmentData?: ViewShipmentDataForApollo;
  type?:
    | "link"
    | "text"
    | "ghost"
    | "default"
    | "primary"
    | "dashed"
    | undefined;
  useAmy?: boolean;
}

export function SupportEmailButton(props: SupportEmailButtonProps) {
  return (
    <SupportEmailButtonBase
      {...props}
      button={(label, onClick) => (
        <Button disabled={props.disabled} type={props.type} onClick={onClick}>
          {label}
        </Button>
      )}
    />
  );
}

export function SupportEmailMenuItem(
  props: SupportEmailButtonProps & MenuItemProps
) {
  return (
    <SupportEmailButtonBase
      {...props}
      button={(label, onClick) => (
        <Menu.Item disabled={props.disabled} onClick={onClick} {...props}>
          {label}
        </Menu.Item>
      )}
    />
  );
}

interface SupportEmailButtonBaseProps extends SupportEmailButtonProps {
  button: (label: string, onClick: () => void) => ReactNode;
}

// experiences for sending customers
export function SupportEmailButtonBase(props: SupportEmailButtonBaseProps) {
  const { previewUpdateHash } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailDescription, setEmailDescription] = useState<
    EmailDescription | undefined
  >();
  const [sending, setSending] = useState(false);
  const [supportAgent, setSupportAgent] = useState<SupportAgent>(
    SupportAgents[0]
  );
  const [attachments, setAttachments] = useState<ShipmentDocument[]>([]);
  const [hasGotFirstPreview, setHasGotFirstPreview] = useState(false);

  const attachmentDocumentIds = attachments.map((o) => o.shipmentDocumentId!!);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function fetchPreview() {
    const response = await props.onFetchPreview(supportAgent);
    console.log(response?.emailBody);
    setEmailDescription(response);
    setHasGotFirstPreview(true);
  }

  useEffect(
    function () {
      console.log("em.effect");
      if (isModalVisible) {
        fetchPreview();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [previewUpdateHash, supportAgent, isModalVisible]
  );

  async function handleOk() {
    setSending(true);
    try {
      const success = await props.onSend(supportAgent, attachmentDocumentIds);
      if (success) {
        setIsModalVisible(false);
        message.success("Email sent!");
      }
    } catch (e: any) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setSending(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={props.label}
        visible={isModalVisible}
        onOk={handleOk}
        okText="✉️ Send email"
        confirmLoading={sending}
        onCancel={handleCancel}
        width={1400}
        destroyOnClose={true}
      >
        <Stack align="left">
          <HorizontalStack
            verticalAlign="middle"
            spacing={16}
            width="100%"
            style={{
              backgroundColor: Colors.VeryVeryLightBlue,
              padding: "8px",
              position: "relative",
              top: "-16px",
            }}
          >
            {!props.useAmy && (
              <SupportEmailBodyExtraItem label="Agent">
                <SupportAgentDropdown
                  supportAgent={supportAgent}
                  onSelect={setSupportAgent}
                />
              </SupportEmailBodyExtraItem>
            )}
            {props.extraFormItems}
            {props.shipmentData && (
              <ManageAttachmentsButton
                buttonText="Manage Email Attachments"
                buttonType="link"
                shipmentData={props.shipmentData}
                attachments={attachments}
                onUpdateAttachments={setAttachments}
                showThumbnails={false}
              />
            )}
          </HorizontalStack>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14, offset: 1 }}
            layout="horizontal"
            colon={false}
          >
            {emailDescription !== undefined && (
              <>
                <Form.Item label="Email Addresses">
                  <Text>{emailDescription?.addresses.join(", ")}</Text>
                </Form.Item>
                <Form.Item label="Subject">
                  <Text>{emailDescription?.subject}</Text>
                </Form.Item>
                <Form.Item label="Email Body">
                  <div
                    style={{
                      border: "1px solid #eee",
                      padding: "16px",
                      width: "1100px",
                    }}
                  >
                    {!hasGotFirstPreview && <Loading />}
                    {hasGotFirstPreview && (
                      <div
                        title="Email Preview"
                        dangerouslySetInnerHTML={{
                          __html: emailDescription.emailBody,
                        }}
                      ></div>
                    )}
                  </div>
                </Form.Item>
              </>
            )}
          </Form>
        </Stack>
      </Modal>
      <Tooltip title={props.tooltip}>
        {props.button(props.label, showModal)}
      </Tooltip>
    </>
  );
}
