import { Select } from "antd";
import SupportAgents from "../../Fixtures/SupportAgents.json";
import { SupportAgent } from "../ViewCompanyScreen";

const { Option } = Select;

interface SupportAgentDropdownProps {
  onSelect: (supportAgent: SupportAgent) => void;
  supportAgent: SupportAgent;
}

export function SupportAgentDropdown(props: SupportAgentDropdownProps) {
  return (
    <Select
      value={props.supportAgent}
      style={{ width: 100 }}
      onChange={props.onSelect}
    >
      {SupportAgents.map(function (value, index) {
        return (
          <Option value={value} key={index}>
            {value.firstName} {value.lastName}
          </Option>
        );
      })}
    </Select>
  );
}
