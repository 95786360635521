import { DatePicker, Form, message, Modal, Tooltip, Typography } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import moment from "moment";
import { useState } from "react";

import TextArea from "antd/lib/input/TextArea";
import { useShipmentsApi } from "../Apis/Apis";
import HorizontalStack from "../Components/HorizontalStack";
import Stack from "../Components/Stack";
import { daysAgo } from "../Helpers/daysAgo";
import { isBlank } from "../Helpers/isNotBlank";
import Spacer from "../Spacer";

const { Text } = Typography;

interface SnoozeInvoiceAuditMenuItemProps {
  shipmentId: string;
  currentSnoozeDate: string | undefined;
  onRefresh: () => Promise<void>;
}

export function SnoozeInvoiceAuditMenuItem(
  props: SnoozeInvoiceAuditMenuItemProps
) {
  function defaultSnoozeDate(): moment.Moment | undefined {
    // If the snooze was set in the past - or it's not set
    // then suggest tomorrow
    if (
      props.currentSnoozeDate === undefined ||
      daysAgo(props.currentSnoozeDate) > 0
    ) {
      return moment().add(1, "days");
    } else {
      // Else use the current snooze date
      return moment(props.currentSnoozeDate);
    }
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const createShipmentsApi = useShipmentsApi();
  const [snoozeUntilDateMoment, setSnoozeUntilDateMoment] = useState<
    moment.Moment | undefined
  >(defaultSnoozeDate());
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (snoozeUntilDateMoment === undefined) {
      message.warn("Pick a date");
      return;
    }
    if (snoozeUntilDateMoment !== undefined && isBlank(note)) {
      message.warn("If snoozing, you must leave a note explaining why");
      return;
    }

    if (daysAgo(snoozeUntilDateMoment.format("YYYY-MM-DD")) >= 0) {
      message.warn("Can only snooze into the future");
      return;
    }

    setLoading(true);
    try {
      const shipmentId = props.shipmentId;
      const snoozeUntilDate = snoozeUntilDateMoment?.format("YYYY-MM-DD");

      const shipmentsApi = await createShipmentsApi();

      await shipmentsApi.snoozeInvoiceAuditUntil({
        shipmentId,
        snoozeInvoiceAuditUntilDate: snoozeUntilDate,
        note,
      });
      await props.onRefresh();
      message.success(`Invoice audit snoozed until ${snoozeUntilDate}`);
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Snooze Invoice Audit"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        destroyOnClose
        confirmLoading={loading}
      >
        <Stack align="left">
          <Text>
            This allows you to snooze an invoice audit. Snooze will be cancelled
            if there is a reply on the customer/carrier audit email threads
          </Text>
          <Spacer height={32} />
          <HorizontalStack>
            <Form
              style={{ width: "700px" }}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 20, offset: 2 }}
              layout="horizontal"
              colon={false}
            >
              <Form.Item label="Note">
                <TextArea
                  style={{ width: "440px", height: "150px" }}
                  value={note}
                  onChange={function (e) {
                    setNote(e.target.value);
                  }}
                  placeholder="Enter the snooze reason here"
                />
              </Form.Item>
              <Form.Item label="Snooze Until">
                <DatePicker
                  style={{ width: "200px" }}
                  value={snoozeUntilDateMoment}
                  onChange={function (m) {
                    if (m !== null) {
                      setSnoozeUntilDateMoment(m);
                    } else {
                      setSnoozeUntilDateMoment(undefined);
                    }
                  }}
                  allowClear
                />
              </Form.Item>
            </Form>
          </HorizontalStack>
        </Stack>
      </Modal>
      <Tooltip
        placement="left"
        overlay="This will snooze invoice audit until a given date"
      >
        <MenuItem onClick={showModal}>⏰ Snooze Invoice Audit</MenuItem>
      </Tooltip>
    </>
  );
}
