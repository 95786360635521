import { message } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import {
  Quote,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { SupportAgent } from "../ViewCompanyScreen";
import { ExtraTextButton } from "./ExtraTextButton";
import { SupportEmailMenuItem } from "./SupportEmailButton";

interface QuoteNotificationEmailMenuItemProps {
  quote: Quote;
  data: ViewShipmentDataForApollo;
  onRefresh: () => Promise<void>;
}

export function QuoteNotificationEmailMenuItem(
  props: QuoteNotificationEmailMenuItemProps
) {
  const shipmentId = props.data.shipmentId;
  const quoteId = props.quote.quoteId!!;
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");

  const hash = JSON.stringify({
    quoteId,
    extraText,
  });

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewQuoteNotificationEmail({
        shipmentId,
        extraText,
        quoteId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendQuoteNotificationEmail({
        shipmentId,
        extraText,
        quoteId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailMenuItem
      label="✉️ Quote Notification Email"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
        </>
      }
      shipmentData={props.data}
    />
  );
}
