import { Form, InputNumber } from "antd";
import { useState } from "react";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import { CreatedAt } from "../../Components/CreatedAt";
import { CustomerInvoiceStateTag } from "../../Components/CustomerInvoiceStateTag";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import Stack from "../../Components/Stack";
import { ViewShipmentButton } from "../../Components/ViewShipmentButton";
import {
  CompanyData,
  CustomerInvoice,
  CustomerInvoiceState,
} from "../../generated-openapi-client";
import { sum } from "../../Helpers/sum";
import { CustomerInvoiceStateDropdown } from "../ViewShipmentScreenComponents/CustomerInvoiceStateDropdown";

interface CompanyInvoicesTabProps {
  companyData: CompanyData;
}

export function CompanyInvoicesTab(props: CompanyInvoicesTabProps) {
  const [customerInvoiceStateFilter, setCustomerInvoiceStateFilter] = useState<
    CustomerInvoiceState | undefined
  >(undefined);
  const [priceFilter, setPriceFilter] = useState<number | null | undefined>();
  const [selectedInvoices, setSelectedInvoices] = useState<CustomerInvoice[]>(
    []
  );
  console.log(`priceFilter=${priceFilter}`);

  function doStateFilter(ci: CustomerInvoice): boolean {
    if (customerInvoiceStateFilter === undefined) {
      return true;
    } else {
      return ci.customerInvoiceState === customerInvoiceStateFilter;
    }
  }

  function doPriceFilter(ci: CustomerInvoice): boolean {
    if (priceFilter === undefined || priceFilter === null) {
      return true;
    } else {
      return ci.amount === priceFilter;
    }
  }

  const filteredInvoices = props.companyData.customerInvoices
    .filter(doStateFilter)
    .filter(doPriceFilter);

  const columns: DataTableColumn<CustomerInvoice>[] = [
    {
      title: "Identifier",
      render: (o) => <div>{o.invoiceIdentifier}</div>,
    },
    {
      title: "State",
      render: (o) => <CustomerInvoiceStateTag customerInvoice={o} />,
    },
    {
      title: "Disputed",
      render: (o) => (o.disputed ? <div>Yes</div> : <div>No</div>),
    },
    {
      title: (
        <Stack align="left">
          <div>Amount</div>
          <div style={{ color: Colors.LightText, fontSize: "8px" }}>
            Total ${sum(filteredInvoices, (ci) => ci.amount!!).toFixed(2)}
          </div>
        </Stack>
      ),
      render: (o) => (
        <div>
          {o.amount} {o.currency}
        </div>
      ),
      sorter: (a, b) => {
        return a.amount!! - b.amount!!;
      },
    },
    {
      title: "Created",
      render: (o) => <CreatedAt timestamp={o.createdAt} days></CreatedAt>,
    },
    {
      title: "Due",
      render: (o) => <CreatedAt timestamp={o.dueDate} days></CreatedAt>,
    },
    {
      title: "Description",
      render: (o) => <div>{o.description}</div>,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.shipmentId!!} />
        </ButtonRow>
      ),
    },
  ];
  return (
    <>
      <ButtonRow>
        <Form.Item label="State">
          <CustomerInvoiceStateDropdown
            value={customerInvoiceStateFilter}
            setValue={setCustomerInvoiceStateFilter}
          />
        </Form.Item>
        <Form.Item label="Price">
          <InputNumber value={priceFilter} onChange={setPriceFilter} />
        </Form.Item>
        {selectedInvoices.length > 0 && (
          <Form.Item label="Selected Total">
            <div>${sum(selectedInvoices, (ci) => ci.amount!!).toFixed(2)}</div>
          </Form.Item>
        )}
      </ButtonRow>

      <DataTable
        pagination={false}
        columns={columns}
        data={filteredInvoices}
        rowKey={function (row) {
          return row.customerInvoiceId!!;
        }}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedInvoices.map((a) => a.customerInvoiceId!!),
          onChange: (
            _selectedRowKeys: React.Key[],
            _selectedRows: CustomerInvoice[]
          ) => {
            console.log(
              `selectedRowKeys: ${_selectedRowKeys}`,
              "selectedRows: ",
              _selectedRows
            );
            setSelectedInvoices(_selectedRows);
          },
        }}
      />
    </>
  );
}
