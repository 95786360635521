import { InfoCircleTwoTone } from "@ant-design/icons";
import { Button, Form, message, Spin, Statistic, Tag, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { useQuotesApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { CarrierFilter } from "../Components/CarrierFilter";
import { CarrierLogoSquare } from "../Components/CarrierLogoSquare";
import Colors from "../Components/Colors";
import { CompanyFilter } from "../Components/CompanyFilter";
import { CreatedAt } from "../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { DateRangePicker } from "../Components/DateRangePicker";
import { Dollar } from "../Components/Dollar";
import { DownloadCarrierComparisonButton } from "../Components/DownloadCarrierComparisonLink";
import { GeoFilterDropdown } from "../Components/GeoFilterDropdown";
import HorizontalStack from "../Components/HorizontalStack";
import { InfoBubble } from "../Components/InfoBubble";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { Percentage } from "../Components/Percentage";
import Stack from "../Components/Stack";
import { StatsRow } from "../Components/StatsRow";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { Volume } from "../Components/Volume";
import { Weight } from "../Components/Weight";
import {
  CountryCode,
  GeoFilter,
  ShipmentReport,
  ShipmentReportInvoiceWarningState,
  ShipmentReportQuoteSummary,
} from "../generated-openapi-client";
import { LineItem } from "../generated-openapi-client/models/LineItem";
import { calculatePercentageOfTrailer } from "../Helpers/calculatePercentageOfTrailer";
import { groupBy } from "../Helpers/groupBy";
import { getMapUrlForViewQuoteScreenDataRow } from "../Helpers/mapUtils";
import { stringToColor } from "../Helpers/stringToColor";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import { OptionalEnumDropdown } from "./ViewShipmentScreenComponents/EnumDropdown";

enum QuoteFilterType {
  NoQuotes = "NoQuotes",
  NotManyQuotes = "NotManyQuotes",
  SuspiciouslyLow = "SuspiciouslyLow",
  CompanySignedUpInLastTwoWeeks = "CompanySignedUpInLastTwoWeeks",
  CompanySignedUpInLastTwoWeeksDaily = "CompanySignedUpInLastTwoWeeksDaily",
  CompanySignedUpInLastTwoWeeksWeekly = "CompanySignedUpInLastTwoWeeksWeekly",
  ShippingRookies = "ShippingRookies",
  NeverBooked = "NeverBooked",
  NeverBookedWeekly = "NeverBookedWeekly",
  NeverBookedDaily = "NeverBookedDaily",
  IncludesFlaggedServices = "IncludesFlaggedServices",
  FlaggedServiceIsInTwoCheapest = "FlaggedServiceIsInTwoCheapest",
  SelectedCarrierIsInTwoCheapest = "SelectedCarrierIsInTwoCheapest",
  LargeShipment = "LargeShipment",
}

interface QuotesStatsProps {
  filteredQuotes: ShipmentReport[];
  carrierIdentifier: string | undefined;
}

const QuotesStatsHeader = styled.div`
  font-size: 14px;
`;
const QuotesStatsItem = styled.div`
  font-size: 13px;
  font-weight: 200;
`;

function QuotesStats(props: QuotesStatsProps) {
  const filteredQuotes = props.filteredQuotes;
  const numberBooked = filteredQuotes.filter((o) => o.booked).length;
  const numberBookedWithCarrier = filteredQuotes.filter(
    (o) =>
      o.booked && o.bookedQuote?.carrierIdentifier === props.carrierIdentifier
  ).length;
  const uniqueShipments = groupBy(
    filteredQuotes,
    (q) => q.shipmentHash ?? "-"
  ).length;
  const totalShipments = filteredQuotes.length;
  const numberCompaniesQuoted = groupBy(
    filteredQuotes,
    (o) => o.companyName
  ).length;
  const numberCompaniesBooked = groupBy(
    filteredQuotes.filter((o) => o.booked),
    (o) => o.companyName
  ).length;

  const conversion = ((numberBooked / uniqueShipments) * 100).toFixed(0);

  return (
    <HorizontalStack verticalAlign="top">
      <Stack align="left">
        <QuotesStatsHeader>Shipments</QuotesStatsHeader>
        <QuotesStatsItem>Unique Quotes: {uniqueShipments}</QuotesStatsItem>
        <QuotesStatsItem>Quotes: {totalShipments}</QuotesStatsItem>
        <QuotesStatsItem>Booked: {numberBooked}</QuotesStatsItem>
        {numberBookedWithCarrier !== undefined && (
          <QuotesStatsItem>
            Booked with carrier: {numberBookedWithCarrier}
          </QuotesStatsItem>
        )}
        <QuotesStatsItem>Acceptance: {conversion}%</QuotesStatsItem>
      </Stack>
      <Spacer width={32} />
      <Stack align="left">
        <QuotesStatsHeader>Companies</QuotesStatsHeader>
        <QuotesStatsItem>Quoted: {numberCompaniesQuoted}</QuotesStatsItem>
        <QuotesStatsItem>Booked: {numberCompaniesBooked}</QuotesStatsItem>
      </Stack>
      <Spacer width={32} />
    </HorizontalStack>
  );
}

export const QuoteFilterDropdown = OptionalEnumDropdown<
  QuoteFilterType,
  typeof QuoteFilterType
>(
  QuoteFilterType,
  "All Quotes",
  function (et) {
    switch (et) {
      case QuoteFilterType.NoQuotes:
        return "No Quotes";
      case QuoteFilterType.NotManyQuotes:
        return "Not Many Quotes";
      case QuoteFilterType.SuspiciouslyLow:
        return "Suspiciously Low";
      case QuoteFilterType.CompanySignedUpInLastTwoWeeks:
        return "Recent Signups (14 days)";
      case QuoteFilterType.CompanySignedUpInLastTwoWeeksDaily:
        return "Recent Signups (14 days) - Daily";
      case QuoteFilterType.CompanySignedUpInLastTwoWeeksWeekly:
        return "Recent Signups (14 days) - Weekly";
      case QuoteFilterType.ShippingRookies:
        return "Shipping Rookies (1-5 shipments)";
      case QuoteFilterType.NeverBooked:
        return "Company Never Booked";
      case QuoteFilterType.NeverBookedDaily:
        return "Company Never Booked - Daily";
      case QuoteFilterType.NeverBookedWeekly:
        return "Company Never Booked - Weekly";

      case QuoteFilterType.IncludesFlaggedServices:
        return "Includes Flagged Services";
      case QuoteFilterType.FlaggedServiceIsInTwoCheapest:
        return "Flagged Service is in two cheapest";
      case QuoteFilterType.SelectedCarrierIsInTwoCheapest:
        return "Selected carrier is in two cheapest";

      case QuoteFilterType.LargeShipment:
        return "Large/Volume Shipment";
    }
  },
  280
);

function MoreDimensionsInfoTable(props: MoreDimensionsInfoProps) {
  const columns: DataTableColumn<LineItem>[] = [
    {
      title: "Handling",
      render: (o) => (
        <div>
          {o.numberHandlingUnits} x {o.handlingUnitType}
        </div>
      ),
    },
    {
      title: "Description",
      render: (o) => <div>{o.description}</div>,
    },
    {
      title: "HU Weight",
      render: (o) => <Weight>{o.weightPerHandlingUnit!!}</Weight>,
    },
    {
      title: "Total Weight",
      render: (o) => (
        <Weight>{o.weightPerHandlingUnit!! * o.numberHandlingUnits!!}</Weight>
      ),
    },
    {
      title: "Dimensions",
      render: (o) => (
        <div>
          {o.length}"x{o.width}"x{o.height}"
        </div>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "white" }}>
      <Stack align="left" style={{ backgroundColor: "white" }}>
        <Spacer height={8} />
        <HorizontalStack align="spread" style={{ width: "100%" }}>
          <StatsRow>
            <Statistic
              title="Pickup Date"
              prefix=""
              value={moment(props.shipmentReport.pickupDate).format(
                "MMM Do YYYY"
              )}
              style={{
                margin: "0 32px",
              }}
            />
            <Statistic
              title="Density"
              prefix=""
              value={props.shipmentReport.density!!.toFixed(1)}
              suffix="pcf"
              style={{
                margin: "0 32px",
              }}
            />
            <Statistic
              title={
                <>
                  Std Skid Equivalents{" "}
                  <InfoBubble overlay="Basically how many standard ie. 48x40x48 skids is this equivalent to" />
                </>
              }
              prefix=""
              suffix="skids"
              value={(props.shipmentReport.totalVolume!! / 53.33).toFixed(1)}
              style={{
                margin: "0 32px",
              }}
            />
            {props.shipmentReport.quotes!!.length > 0 && (
              <Statistic
                title={<>Price per cuft per mile</>}
                prefix="$"
                suffix=""
                value={(
                  props.shipmentReport.quotes!![0].priceCad!! /
                  props.shipmentReport.totalVolume!! /
                  props.shipmentReport.distanceMiles!!
                ).toFixed(1)}
                style={{
                  margin: "0 32px",
                }}
              />
            )}
            {props.shipmentReport.insuranceRequested && (
              <Statistic
                title={<>Insurance Requested </>}
                prefix="$"
                suffix={props.shipmentReport.insuranceCurrency}
                value={props.shipmentReport.insuranceAmount!!}
                style={{
                  margin: "0 32px",
                }}
              />
            )}
            {!props.shipmentReport.insuranceRequested && (
              <Statistic
                title={<>Insurance Not Requested</>}
                prefix=""
                suffix=""
                value="-"
                style={{
                  margin: "0 32px",
                }}
              />
            )}
          </StatsRow>
          <ButtonRow>
            <Button
              href={getMapUrlForViewQuoteScreenDataRow(props.shipmentReport)}
              type="ghost"
              target="_new"
            >
              View Map
            </Button>
          </ButtonRow>
        </HorizontalStack>
        <Spacer height={8} />
        <DataTable
          pagination={false}
          columns={columns}
          data={props.shipmentReport.lineItems!!}
        />
      </Stack>
    </div>
  );
}
interface MoreDimensionsInfoProps {
  shipmentReport: ShipmentReport;
}

export function MoreDimensionsInfo(props: MoreDimensionsInfoProps) {
  return (
    <Tooltip
      placement="right"
      overlay={<MoreDimensionsInfoTable {...props} />}
      overlayStyle={{ minWidth: "1000px" }}
    >
      <Button
        type="link"
        icon={<InfoCircleTwoTone twoToneColor={Colors.LightBlue} />}
        disabled={true}
      ></Button>
    </Tooltip>
  );
}

interface QuoteSummaryComponentProps {
  quote: ShipmentReportQuoteSummary;
}

function borderStyle(quote: ShipmentReportQuoteSummary) {
  if (quote.booked) {
    return "1px solid #389e0d";
  } else {
    return "none";
  }
}

function backgroundColor(quote: ShipmentReportQuoteSummary) {
  if (quote.booked) {
    return "#b7eb8f";
  } else {
    return "transparent";
  }
}

function opacity(quote: ShipmentReportQuoteSummary) {
  if (quote.hiddenFromCustomer) {
    return 0.5;
  } else {
    return 1.0;
  }
}

export function QuoteSummaryComponent(props: QuoteSummaryComponentProps) {
  const quote = props.quote;
  return (
    <Stack
      align="center"
      style={{
        width: "90px",
        border: borderStyle(quote),
        backgroundColor: backgroundColor(quote),
        padding: "4px",
        opacity: opacity(quote),
      }}
    >
      <div style={{ border: borderStyle(quote) }}>
        <CarrierLogoSquare
          carrierIdentifier={quote.carrierIdentifier}
          brokeredCarrierIdentifier={quote.brokeredCarrierIdentifier}
          width={64}
          height={64}
        />
      </div>

      <div style={{ fontSize: "9px", maxWidth: "130px", textAlign: "center" }}>
        {quote.serviceIdentifier!!}
      </div>

      <Spacer height={8} />
      <div style={{ fontSize: "12px", fontWeight: "600" }}>
        <Dollar>{quote.priceCad!!}</Dollar>
      </div>
      <Spacer height={2} />
      <div style={{ fontSize: "11px" }}>
        {quote.transitWindowDescription} Days
      </div>
      <Spacer height={4} />
      <div
        style={{
          fontSize: "10px",
          fontWeight: "500",
          maxWidth: "100px",
          textAlign: "center",
        }}
      >
        {quote.serviceDescription}
      </div>
    </Stack>
  );
}

interface AndMoreProps {
  quotes: ShipmentReportQuoteSummary[];
}

export function AndMore(props: AndMoreProps) {
  if (props.quotes.length === 0) {
    return <></>;
  }

  return (
    <Tooltip
      placement="bottom"
      overlay={
        <HorizontalStack spacing={8} style={{ marginLeft: "-8px" }}>
          {props.quotes.map(function (q) {
            return <QuoteSummaryComponent quote={q} />;
          })}
        </HorizontalStack>
      }
      overlayStyle={{ minWidth: `${props.quotes.length * 90}px` }}
    >
      <div>And {props.quotes.length} more</div>
    </Tooltip>
  );
}

interface QuotesTableProps {
  quotes: ShipmentReport[];
  onRefresh: () => Promise<void>;
}

function QuotesTable(props: QuotesTableProps) {
  const columns: DataTableColumn<ShipmentReport>[] = [
    {
      title: "Company",
      render: (o) => (
        <Stack
          align="left"
          style={{
            borderLeft: `8px solid ${stringToColor(o.shipmentHash!!)}`,
            paddingLeft: "16px",
          }}
        >
          <div style={{ fontWeight: 500 }}>
            <HorizontalStack>
              {o.companyName}
              <Spacer width={4} />
              <span>{o.booked ? <Tag color="green">Booked</Tag> : ""}</span>
              <span>
                {o.insuranceRequested ? (
                  <Tag color="purple">Insurance</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.deletedDescription ? (
                  <Tag color="red">Deleted : {o.deletedDescription}</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.tags?.map((s) => (
                  <Tag color="orange">{s}</Tag>
                ))}
                {o.problems?.map((s) => (
                  <Tag color="magenta">🚩 {s}</Tag>
                ))}
              </span>
              <span>
                {o.manualQuotingRequested ? (
                  <Tag color="blue">🦸‍♀️ Manual Quoting Requested</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.invoiceWarningState !==
                ShipmentReportInvoiceWarningState.NoWarning ? (
                  <Tag color="red">{o.invoiceWarningState}</Tag>
                ) : (
                  ""
                )}
              </span>
            </HorizontalStack>
          </div>
          <div>
            {o.pickupCityState} to {o.deliveryCityState}{" "}
          </div>
          <Spacer height={8} />
          <HorizontalStack style={{ fontSize: "12px" }}>
            <div>Units:</div>
            <Spacer width={2} />
            <div>{o.totalHandlingUnits!!}</div>
            <Spacer width={8} />
            <div>Weight:</div>
            <Spacer width={2} />
            <Weight>{o.totalWeight!!}</Weight>
            <Spacer width={8} />
            <div>Volume:</div>
            <Spacer width={2} />
            <Volume>{o.totalVolume!!}</Volume>
            <Spacer width={8} />
            <div>Trailer:</div>
            <Percentage>
              {calculatePercentageOfTrailer(o.lineItems!!)}
            </Percentage>
            <MoreDimensionsInfo shipmentReport={o} />
          </HorizontalStack>
          <Spacer height={8} />
          <div>{o.lineItems!![0].description}</div>
          <Spacer height={8} />
          <div style={{ fontSize: "12px", color: Colors.LightText }}>
            <CreatedAt timestamp={o.quotedAt} />
          </div>
        </Stack>
      ),
      sorter: function (a, b) {
        return a.companyName!!.localeCompare(b.companyName!!);
      },
    },
    {
      title: "Quotes",
      render: (o) => (
        <HorizontalStack spacing={16} style={{ marginLeft: "-16px" }}>
          {o.quotes!!.length === 0 && <div>No quotes</div>}
          {o.quotes!!.slice(0, 4).map(function (q) {
            return <QuoteSummaryComponent quote={q} />;
          })}
          <AndMore quotes={o.quotes!!.slice(4)} />
        </HorizontalStack>
      ),
    },

    {
      title: "Actions",
      render: function (o) {
        return (
          <ButtonRow>
            <ViewShipmentButton shipmentId={o.shipmentId!!} />
          </ButtonRow>
        );
      },
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.quotes.sort(function (a, b) {
        return moment(a.quotedAt).valueOf() - moment(b.quotedAt).valueOf();
      })}
    />
  );
}

function getCompany(csq: ShipmentReport) {
  return { name: csq.companyName!!, id: csq.companyId!! };
}

function getCompanyNames(
  quotes: ShipmentReport[]
): Array<{ id: string; name: string }> {
  const companies = quotes.map((c) => getCompany(c));

  const m = new Map<string, string>();
  companies.forEach(function (c) {
    m.set(c.id, c.name);
  });

  console.log(m);

  const results: Array<{ id: string; name: string }> = [];
  m.forEach(function (name, id) {
    results.push({ id, name });
  });

  return results;
}

export function ViewQuotesScreen() {
  const createQuotesApi = useQuotesApi();
  const [quotes, setQuotes] = useState<ShipmentReport[] | undefined>(undefined);
  const today = moment().format("YYYY-MM-DD");
  const query = useQuery();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<string>(
    (query.startDate as string | undefined) || today
  );
  const [endDate, setEndDate] = useState<string>(
    (query.endDate as string | undefined) || today
  );
  const [companyIdFilter, setCompanyIdFilter] = useState<string | undefined>(
    query.companyIdFilter as string | undefined
  );
  const [carrierIdentifier, setCarrierIdentifier] = useState<
    string | undefined
  >(query.carrierIdentifier as string | undefined);
  const [comparisonCarrierIdentifier, setComparisonCarrierIdentifier] =
    useState<string | undefined>(query.carrierIdentifier as string | undefined);
  const [filter, setFilter] = useState<QuoteFilterType | undefined>(
    query.filter as QuoteFilterType | undefined
  );
  const [geoFilter, setGeoFilter] = useState<GeoFilter | undefined>(
    query.geoFilter as GeoFilter | undefined
  );
  const [loading, setLoading] = useState(false);

  function buildUrl() {
    let base = `/quotes?startDate=${startDate}&endDate=${endDate}`;
    if (companyIdFilter !== undefined) {
      base = base + `&companyIdFilter=${companyIdFilter}`;
    }

    if (carrierIdentifier !== undefined) {
      base = base + `&carrierIdentifier=${carrierIdentifier}`;
    }

    if (filter !== undefined) {
      base = base + `&filter=${filter}`;
    }

    if (geoFilter !== undefined) {
      base = base + `&geoFilter=${geoFilter}`;
    }

    return base;
  }

  const url = buildUrl();

  useEffect(
    function () {
      navigate(url, {
        replace: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  async function refresh() {
    setLoading(true);
    try {
      console.log(`refresh`, { startDate, endDate });
      const quotesApi = await createQuotesApi();
      const response = await quotesApi.viewQuotesScreenData({
        startDate,
        endDate,
      });
      setQuotes(response);
    } catch (e: any) {
      message.error(`Oops something went wrong ${e}`);
    }
    setLoading(false);
  }

  useEffect(
    function () {
      refresh();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate]
  );

  function applyNoQuotesFilter(q: ShipmentReport) {
    if (q.quotes!!.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  function applyNotManyQuotesFilter(q: ShipmentReport) {
    if (q.quotes!!.length > 0 && q.quotes!!.length < 4) {
      return true;
    } else {
      return false;
    }
  }

  function applySuspiciouslyLowFilter(q: ShipmentReport) {
    if (q.quotes!!.length < 2) {
      return false;
    }

    const cheapestQuote = q.quotes!![0];
    const secondCheapestQuote = q.quotes!![1];

    // If it's less than half - that's weird
    const suspiciouslyCheap =
      cheapestQuote.priceCad!! < secondCheapestQuote.priceCad!! * 0.5;

    if (suspiciouslyCheap) {
      return true;
    } else {
      return false;
    }
  }

  function applyCompanySignedUpInLastTwoWeeksFilter(q: ShipmentReport) {
    return moment(q.companyCreationDate).isAfter(moment().subtract(2, "weeks"));
  }

  function applyCompanySignedUpInLastTwoWeeksDailyFilter(q: ShipmentReport) {
    return (
      moment(q.companyCreationDate).isAfter(moment().subtract(2, "weeks")) &&
      q.companyShipmentFrequency === "Daily"
    );
  }

  function applyCompanySignedUpInLastTwoWeeksWeeklyFilter(q: ShipmentReport) {
    return (
      moment(q.companyCreationDate).isAfter(moment().subtract(2, "weeks")) &&
      q.companyShipmentFrequency === "Weekly"
    );
  }

  function applyShipmentRookiesFilter(q: ShipmentReport) {
    return (
      q.numberOfShipmentsCompanyBooked!! >= 1 &&
      q.numberOfShipmentsCompanyBooked!! <= 5
    );
  }

  function applyNeverBookedFilter(q: ShipmentReport) {
    return q.numberOfShipmentsCompanyBooked === 0;
  }

  function applyNeverBookedFilterDaily(q: ShipmentReport) {
    return (
      q.numberOfShipmentsCompanyBooked === 0 &&
      q.companyShipmentFrequency === "Daily"
    );
  }

  function applyNeverBookedFilterWeekly(q: ShipmentReport) {
    return (
      q.numberOfShipmentsCompanyBooked === 0 &&
      q.companyShipmentFrequency === "Weekly"
    );
  }

  function applyIncludesFlaggedServicesFilter(q: ShipmentReport) {
    return q.quotes!!.some((o) => o.carrierIntegrationFlagged);
  }

  function applyFlaggedServiceIsInTwoCheapestFilter(q: ShipmentReport) {
    return q.quotes!!.slice(0, 2).some((o) => o.carrierIntegrationFlagged);
  }

  function applySelectedCarrierIsInTwoCheapestFilter(q: ShipmentReport) {
    return q
      .quotes!!.slice(0, 2)
      .some((o) => o.carrierIdentifier === carrierIdentifier);
  }

  function applyLargeShipmentFilter(q: ShipmentReport) {
    // 48x40x48 pallet is 53ft
    return (
      q.standardDryVanLinearFeet!! >= 10 ||
      q.totalVolume!! > 6 * 53 ||
      q.totalWeight!! >= 4000
    );
  }

  function applyGeneralFilter(q: ShipmentReport): boolean {
    if (filter === undefined) {
      return true;
    }

    switch (filter) {
      case QuoteFilterType.NoQuotes:
        return applyNoQuotesFilter(q);
      case QuoteFilterType.NotManyQuotes:
        return applyNotManyQuotesFilter(q);
      case QuoteFilterType.SuspiciouslyLow:
        return applySuspiciouslyLowFilter(q);
      case QuoteFilterType.CompanySignedUpInLastTwoWeeks:
        return applyCompanySignedUpInLastTwoWeeksFilter(q);
      case QuoteFilterType.CompanySignedUpInLastTwoWeeksDaily:
        return applyCompanySignedUpInLastTwoWeeksDailyFilter(q);
      case QuoteFilterType.CompanySignedUpInLastTwoWeeksWeekly:
        return applyCompanySignedUpInLastTwoWeeksWeeklyFilter(q);
      case QuoteFilterType.ShippingRookies:
        return applyShipmentRookiesFilter(q);
      case QuoteFilterType.NeverBooked:
        return applyNeverBookedFilter(q);
      case QuoteFilterType.NeverBookedWeekly:
        return applyNeverBookedFilterWeekly(q);
      case QuoteFilterType.NeverBookedDaily:
        return applyNeverBookedFilterDaily(q);
      case QuoteFilterType.IncludesFlaggedServices:
        return applyIncludesFlaggedServicesFilter(q);
      case QuoteFilterType.FlaggedServiceIsInTwoCheapest:
        return applyFlaggedServiceIsInTwoCheapestFilter(q);
      case QuoteFilterType.SelectedCarrierIsInTwoCheapest:
        return applySelectedCarrierIsInTwoCheapestFilter(q);
      case QuoteFilterType.LargeShipment:
        return applyLargeShipmentFilter(q);
    }
  }

  function applyCompanyFilter(q: ShipmentReport) {
    if (companyIdFilter) {
      return q.companyId === companyIdFilter;
    } else {
      return true;
    }
  }

  function applyCarrierFilter(q: ShipmentReport) {
    if (carrierIdentifier === undefined) {
      return true;
    } else {
      return q.quotes!!.some(
        (qq) => qq.carrierIdentifier === carrierIdentifier
      );
    }
  }

  function applyCompanyHashFilter(
    q: ShipmentReport,
    index: number,
    array: ShipmentReport[]
  ) {
    const shipmentsWithSameHash = array.filter(
      (a) => a.shipmentHash === q.shipmentHash
    );
    const anythingBooked = shipmentsWithSameHash.some((a) => a.booked);
    if (anythingBooked === false) {
      // Nothing was booked, so don't filter anything out. Show all the variations
      return true;
    } else {
      // Something in the group was booked, so only keep the booked one
      return q.booked;
    }
  }

  function applyGeoFilter(q: ShipmentReport) {
    if (geoFilter === undefined) {
      return true;
    }

    switch (geoFilter) {
      case GeoFilter.DomesticCanada:
        return (
          q.pickupCountry === CountryCode.Ca &&
          q.deliveryCountry === CountryCode.Ca
        );
      case GeoFilter.DomesticUsa:
        return (
          q.pickupCountry === CountryCode.Us &&
          q.deliveryCountry === CountryCode.Us
        );
      case GeoFilter.CrossBorder:
        return (
          (q.pickupCountry === CountryCode.Ca &&
            q.deliveryCountry === CountryCode.Us) ||
          (q.pickupCountry === CountryCode.Us &&
            q.deliveryCountry === CountryCode.Ca)
        );
      case GeoFilter.CrossBorderNorth:
        return (
          q.pickupCountry === CountryCode.Us &&
          q.deliveryCountry === CountryCode.Ca
        );
      case GeoFilter.CrossBorderSouth:
        return (
          q.pickupCountry === CountryCode.Ca &&
          q.deliveryCountry === CountryCode.Us
        );
    }
  }

  if (quotes === undefined) {
    return <Loading />;
  } else {
    const filteredQuotes = quotes
      .filter(applyCarrierFilter)
      .filter(applyCompanyFilter)
      .filter(applyGeneralFilter)
      .filter(applyGeoFilter)
      .filter(applyCompanyHashFilter);

    return (
      <>
        <Page
          title={
            <Stack align="left">
              <HorizontalStack>
                {loading && <Spin size="small" />}
                {!loading && "🚚"}
                <Spacer width={8} />
                <div>Quotes</div>
              </HorizontalStack>
            </Stack>
          }
          tags={[]}
          extra={[]}
          stats={[
            <HorizontalStack align="right" width="100%">
              <Stack align="left">
                <ButtonRow>
                  <Form.Item label="Date Range">
                    <DateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onClear={function () {}}
                      onChange={function (_startDate, _endDate) {
                        setStartDate(_startDate);
                        setEndDate(_endDate);
                      }}
                    />
                  </Form.Item>
                  <Spacer width={18} />
                  <Stack align="left">
                    <Form.Item label="Filter by carrier">
                      <CarrierFilter
                        carrierIdentifier={carrierIdentifier}
                        onFilter={setCarrierIdentifier}
                      />
                    </Form.Item>
                    <Form.Item label="Filter by company">
                      <CompanyFilter
                        companies={getCompanyNames(quotes)}
                        onFilter={function (companyId) {
                          setCompanyIdFilter(companyId);
                        }}
                      />
                    </Form.Item>
                  </Stack>
                  <Spacer width={8} />
                  <Stack align="left">
                    <Form.Item label="Quote Filter">
                      <QuoteFilterDropdown
                        value={filter}
                        setValue={setFilter}
                      />
                    </Form.Item>
                    <Form.Item label="Geo">
                      <GeoFilterDropdown
                        value={geoFilter}
                        setValue={setGeoFilter}
                      />
                    </Form.Item>
                  </Stack>
                  <Spacer width={8} />
                  <QuotesStats
                    filteredQuotes={filteredQuotes}
                    carrierIdentifier={carrierIdentifier}
                  />
                </ButtonRow>
              </Stack>
            </HorizontalStack>,
          ]}
        >
          <PageTitle>🚚 Quotes</PageTitle>
          <div style={{ opacity: loading ? 0.3 : 1 }}>
            <Stack align="left">
              <ButtonRow style={{ marginBottom: "8px" }}>
                <Form.Item label="Compare with carrier">
                  <CarrierFilter
                    carrierIdentifier={comparisonCarrierIdentifier}
                    onFilter={setComparisonCarrierIdentifier}
                  />
                </Form.Item>

                <div style={{ position: "relative", top: "-12px" }}>
                  <DownloadCarrierComparisonButton
                    quotes={filteredQuotes}
                    carrierIdentifier={comparisonCarrierIdentifier}
                  />
                </div>
              </ButtonRow>
              <QuotesTable quotes={filteredQuotes} onRefresh={refresh} />
            </Stack>
          </div>
        </Page>
      </>
    );
  }
}
