import { message } from "antd";
import { useSupportEmailApi } from "../../Apis/Apis";
import {
  ShipmentState,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { SupportAgent } from "../ViewCompanyScreen";
import { SupportEmailButton } from "./SupportEmailButton";

interface RequestPhotosOfMissingFreightSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
}

export function RequestPhotosOfMissingFreightSupportEmailButton(
  props: RequestPhotosOfMissingFreightSupportEmailButtonProps
) {
  const shipmentId = props.data.shipmentId;
  const shipment = props.data.shipment.shipment;
  const shipmentState = shipment.state;
  const createSupportEmailApi = useSupportEmailApi();

  function showButton() {
    switch (shipmentState) {
      case ShipmentState.BookingConfirmed:
        return true;
      case ShipmentState.BookingFailed:
        return false;
      case ShipmentState.BookingRequested:
        return false;
      case ShipmentState.Cancelled:
        return false;
      case ShipmentState.Delivered:
        return false;
      case ShipmentState.InTransit:
        return true;
      case ShipmentState.QuoteRequested:
        return false;
      case ShipmentState.Quoted:
        return false;
    }
  }

  if (!showButton()) {
    return <></>;
  }

  const hash = JSON.stringify({
    shipmentId,
  });

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewRequestPhotosOfMissingFreightSupportEmail({
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendRequestPhotosOfMissingFreightSupportEmail({
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="Request Photos from Customer"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={<></>}
      shipmentData={props.data}
      type="ghost"
    />
  );
}
