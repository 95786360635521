import { Tag } from "antd";
import styled from "styled-components/macro";
import Colors from "../Components/Colors";
import {
  AssignInvoiceAuditBase,
  colorForInvoiceAssigneeAddress,
  nameForInvoiceAssigneeAddress,
} from "./ViewShipmentScreenComponents/AssignInvoiceAuditMenuItem";

interface InvoiceAuditAssigneeTagProps {
  invoiceAuditAssignee: string | undefined;
  shipmentId: string;
  refresh: () => Promise<void>;
}

interface InvoiceAuditAssigneeBareTagProps {
  invoiceAuditAssignee: string | undefined;
  onClick: () => void;
}

export const TagInner = styled.div`
  font-size: 16px;
  padding: 4px;
  font-weight: 600;
`;

function InvoiceAuditAssigneeBareTag(props: InvoiceAuditAssigneeBareTagProps) {
  const invoiceAuditAssignee = props.invoiceAuditAssignee;

  return (
    <div style={{ cursor: "pointer" }} onClick={props.onClick}>
      {invoiceAuditAssignee === undefined && (
        <TagInner style={{ color: Colors.LightText }}>Unassigned</TagInner>
      )}
      {invoiceAuditAssignee !== undefined && (
        <Tag color={colorForInvoiceAssigneeAddress(invoiceAuditAssignee)}>
          <TagInner>
            {nameForInvoiceAssigneeAddress(invoiceAuditAssignee)}
          </TagInner>
        </Tag>
      )}
    </div>
  );
}

export function InvoiceAuditAssigneeTag(props: InvoiceAuditAssigneeTagProps) {
  return (
    <>
      <AssignInvoiceAuditBase
        {...props}
        component={function (showModal: () => void) {
          return <InvoiceAuditAssigneeBareTag onClick={showModal} {...props} />;
        }}
      />
    </>
  );
}
