import { EyeInvisibleTwoTone, EyeTwoTone } from "@ant-design/icons";
import { Menu, Table } from "antd";
import { ButtonRow } from "../../Components/ButtonRow";
import { CreatedAt } from "../../Components/CreatedAt";
import Stack from "../../Components/Stack";
import { safeColumns } from "../../Helpers/safeColumns";
import Spacer from "../../Spacer";
import { ShipmentDocumentVisibilty } from "../../generated-openapi-client";
import { ChangeDocumentTypeMenuItem } from "./ChangeDocumentTypeMenuItem";
import { DeleteDocumentMenuItem } from "./DeleteDocumentMenuItem";
import { DocumentSourceTag } from "./DocumentSourceTag";
import { DocumentThumbnail } from "./DocumentThumbnail";
import { DocumentTypeTag } from "./DocumentTypeTag";
import { TabProps } from "./TabProps";
import { UploadDocumentsButton } from "./UploadDocumentsButton";
import { ViewDocumentButton } from "./ViewDocumentButton";

function DocumentsTable(props: TabProps) {
  return (
    <Table
      columns={safeColumns<ShipmentDocumentVisibilty>([
        {
          title: "Visible to Customer?",
          render: function (_, o: ShipmentDocumentVisibilty) {
            if (!o.visibleToCustomer) {
              return <EyeInvisibleTwoTone twoToneColor="#ccc" />;
            } else {
              return <EyeTwoTone />;
            }
          },
        },
        {
          title: "Document Type",
          render: (_, o: ShipmentDocumentVisibilty) => (
            <Stack align="center">
              <DocumentTypeTag
                documentType={o.shipmentDocument.documentType!!}
              />
              <Spacer height={8} />
              <DocumentThumbnail document={o.shipmentDocument} />
            </Stack>
          ),
        },
        {
          title: "Source",
          render: (_, o: ShipmentDocumentVisibilty) => (
            <DocumentSourceTag source={o.shipmentDocument.source!!} />
          ),
        },
        {
          title: "Created By",
          dataIndex: "createdBy",
          key: "createdBy",
          render: (createdBy) => <div>{createdBy}</div>,
        },
        {
          title: "Created At",
          render: (_, o: ShipmentDocumentVisibilty) => (
            <CreatedAt timestamp={o.shipmentDocument.createdAt} />
          ),
        },
        {
          title: "Actions",
          render: function (_, document: ShipmentDocumentVisibilty) {
            return (
              <ButtonRow
                extrasMenu={
                  <Menu>
                    {document.shipmentDocument.frontUrl &&
                      document.shipmentDocument.frontUrl != "-" && (
                        <Menu.Item>
                          <a href={document.shipmentDocument.frontUrl}>
                            Front Link
                          </a>
                        </Menu.Item>
                      )}
                    <ChangeDocumentTypeMenuItem
                      document={document.shipmentDocument}
                      onRefresh={props.onRefresh}
                    />
                    <DeleteDocumentMenuItem
                      document={document.shipmentDocument}
                      onRefresh={props.onRefresh}
                    />
                  </Menu>
                }
              >
                <ViewDocumentButton
                  onRefresh={props.onRefresh}
                  document={document.shipmentDocument}
                  shipmentData={props.shipmentData}
                />
              </ButtonRow>
            );
          },
        },
      ])}
      dataSource={props.shipmentData.documentsTab.documents}
      pagination={false}
    />
  );
}

export function DocumentsTab(props: TabProps) {
  return (
    <Stack align="left">
      <UploadDocumentsButton {...props} />
      <Spacer height={16} />
      <div>
        Note: Carrier Scanned BOLs + Cargo Claim Forms + Unknown documents are
        hidden from customer. All others are visible.
      </div>
      <Spacer height={16} />
      <DocumentsTable {...props} />
    </Stack>
  );
}
