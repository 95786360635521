import { Button, Modal, Table } from "antd";
import { ButtonType } from "antd/lib/button";
import { useState } from "react";
import { ButtonRow } from "../../Components/ButtonRow";
import { CreatedAt } from "../../Components/CreatedAt";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { safeColumns } from "../../Helpers/safeColumns";
import Spacer from "../../Spacer";
import {
  DocumentType,
  ShipmentDocument,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { DocumentThumbnail } from "../ViewShipmentScreenComponents/DocumentThumbnail";
import { DocumentTypeTag } from "../ViewShipmentScreenComponents/DocumentTypeTag";
import { ViewDocumentButton } from "../ViewShipmentScreenComponents/ViewDocumentButton";

interface DocumentsTableProps {
  shipmentData: ViewShipmentDataForApollo;
  attachments: ShipmentDocument[];
  onUpdateAttachments: (documents: ShipmentDocument[]) => void;
}
interface ManageAttachmentsButtonProps extends DocumentsTableProps {
  buttonText: string;
  buttonType: ButtonType;
  showThumbnails: boolean;
}

function DocumentsTable(props: DocumentsTableProps) {
  const onRow = (record: ShipmentDocument) => ({
    onClick: (event: React.MouseEvent) => {
      // Check if the click is not on the action button
      if (!(event.target as HTMLElement).closest(".action")) {
        const newSelectedRows = props.attachments.some(
          (a) => a.shipmentDocumentId === record.shipmentDocumentId
        )
          ? props.attachments.filter(
              (a) => a.shipmentDocumentId !== record.shipmentDocumentId
            )
          : [...props.attachments, record];

        props.onUpdateAttachments(newSelectedRows);
      }
    },
  });

  return (
    <Table
      pagination={false}
      rowKey={function (row) {
        return row.shipmentDocumentId!!;
      }}
      rowSelection={{
        type: "checkbox",
        selectedRowKeys: props.attachments.map((a) => a.shipmentDocumentId!!),
        onChange: (
          _selectedRowKeys: React.Key[],
          _selectedRows: ShipmentDocument[]
        ) => {
          console.log(
            `selectedRowKeys: ${_selectedRowKeys}`,
            "selectedRows: ",
            _selectedRows
          );
          props.onUpdateAttachments(_selectedRows);
          //   setSelectedS3Keys(
          //     _selectedRows.map(function (row) {
          //       return row.s3Key!!;
          //     })
          //   );
        },
      }}
      onRow={onRow}
      columns={safeColumns<ShipmentDocument>([
        {
          title: "Document Type",
          dataIndex: "documentType",
          key: "documentType",
          render: (documentType: DocumentType, o: ShipmentDocument) => (
            <>
              <DocumentTypeTag documentType={documentType} />
              <Spacer height={8} />
              <DocumentThumbnail document={o} />
            </>
          ),
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (createdAt) => <CreatedAt timestamp={createdAt} />,
        },
        {
          title: "Actions",
          dataIndex: "shipmentDocumentId",
          key: "shipmentDocumentId",
          render: function (documentId: string, document: ShipmentDocument) {
            return (
              <ButtonRow className="action">
                <ViewDocumentButton
                  document={document}
                  shipmentData={props.shipmentData}
                />
              </ButtonRow>
            );
          },
        },
      ])}
      dataSource={props.shipmentData.documentsTab.documents.map(
        (o) => o.shipmentDocument
      )}
    />
  );
}

interface AttachmentThumbnailsProps {
  shipmentData: ViewShipmentDataForApollo;
  attachments: ShipmentDocument[];
}

function AttachmentThumbnails(props: AttachmentThumbnailsProps) {
  return (
    <HorizontalStack
      verticalAlign="top"
      spacing={16}
      style={{ marginLeft: "-16px" }}
    >
      {props.attachments.map(function (sd) {
        return (
          <Stack align="center" style={{ width: "140px" }}>
            <DocumentTypeTag documentType={sd.documentType!!} />
            <Spacer height={8} />
            <DocumentThumbnail document={sd} />
          </Stack>
        );
      })}
    </HorizontalStack>
  );
}

export function ManageAttachmentsButton(props: ManageAttachmentsButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={"Manage Attachments"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        okText="Done"
        width={900}
        cancelText={undefined}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <DocumentsTable
          shipmentData={props.shipmentData}
          attachments={props.attachments}
          onUpdateAttachments={props.onUpdateAttachments}
        />
      </Modal>

      <Stack align="left">
        <Button onClick={showModal} type={props.buttonType}>
          {props.buttonText} ({props.attachments.length})
        </Button>
        {props.showThumbnails && (
          <>
            <Spacer height={16} />
            <AttachmentThumbnails
              attachments={props.attachments}
              shipmentData={props.shipmentData}
            />
          </>
        )}
      </Stack>
    </>
  );
}
