import { Form, Menu, message, Modal } from "antd";
import React, { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { OpenAuditLine } from "../../generated-openapi-client";
import { stringToColor } from "../../Helpers/stringToColor";
import { AdminDropdown } from "./AdminDropdown";

interface AssignInvoiceAuditMenuItemProps {
  openAuditLine: OpenAuditLine;
  invoiceAuditAssignee: string | undefined;
  shipmentId: string;
  refresh: () => Promise<void>;
}

interface AssignInvoiceAuditBaseProps {
  invoiceAuditAssignee: string | undefined;
  shipmentId: string;
  refresh: () => Promise<void>;
  component: (showModal: () => void) => React.ReactNode;
}

export const invoiceAssignees = [
  { email: "chris@freightsimple.com", name: "Chris", color: "purple" },
  { email: "andrea@freightsimple.com", name: "Andrea", color: "green" },
];

export function nameForInvoiceAssigneeAddress(email: string): string {
  return invoiceAssignees.find((ia) => ia.email === email)!!.name;
}

export function colorForInvoiceAssigneeAddress(email: string): string {
  return stringToColor(email);
}

export function AssignInvoiceAuditMenuItem(
  props: AssignInvoiceAuditMenuItemProps
) {
  return (
    <AssignInvoiceAuditBase
      {...props}
      component={function (showModal: () => void) {
        return <Menu.Item onClick={showModal}>Assign Invoice Audit</Menu.Item>;
      }}
    />
  );
}

export function AssignInvoiceAuditBase(props: AssignInvoiceAuditBaseProps) {
  const title = "Assign";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [assignee, setAssignee] = useState(props.invoiceAuditAssignee);
  const createShipmentsApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);
  const shipmentId = props.shipmentId!!;

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (assignee === undefined) {
      message.warn("No assignee picked");
      return;
    }
    setLoading(true);
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.setInvoiceAuditAssignee({
        shipmentId,
        invoiceAuditAssignee: assignee,
      });
      await props.refresh();
      setIsModalVisible(false);
    } catch (e) {
      message.error("oops. Something went wrong");
    }

    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
        confirmLoading={loading}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 19, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Invoice Audit Assignee">
            <AdminDropdown
              value={assignee}
              setValue={setAssignee}
              counter={undefined}
            />
          </Form.Item>
        </Form>
      </Modal>
      {props.component(showModal)}
    </>
  );
}
