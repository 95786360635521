import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Radio,
  Statistic,
  Table,
  Tag,
  Typography,
} from "antd";
import MenuDivider from "antd/lib/menu/MenuDivider";
import Dragger from "antd/lib/upload/Dragger";
import { useEffect, useState } from "react";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { CarrierFilter } from "../../Components/CarrierFilter";
import { CarrierInvoiceApprovalTypeTag } from "../../Components/CarrierInvoiceApprovalTag";
import { CarrierInvoiceSourceTag } from "../../Components/CarrierInvoiceSourceTag";
import { CarrierInvoiceStateTag } from "../../Components/CarrierInvoiceStateTag";
import CarrierLogo from "../../Components/CarrierLogo";
import Colors from "../../Components/Colors";
import { CreatedAt } from "../../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import { Dollar } from "../../Components/Dollar";
import HorizontalStack from "../../Components/HorizontalStack";
import { InfoBubble } from "../../Components/InfoBubble";
import { LinkButton } from "../../Components/LinkButton";
import Stack from "../../Components/Stack";
import { StatisticText } from "../../Components/StatisticText";
import { UrlMenuItem } from "../../Components/UrlMenuItem";
import { UsedCouponsAlert } from "../../Components/UsedCouponsAlert";
import { ViewPdfsSideBySideButton } from "../../Components/ViewPdfsSideBySideButton";
import {
  CarrierInvoiceApprovalType,
  CustomerInvoiceState,
  CustomerInvoiceType,
} from "../../generated-openapi-client";
import { CarrierCreditNote } from "../../generated-openapi-client/models/CarrierCreditNote";
import { CarrierInvoice } from "../../generated-openapi-client/models/CarrierInvoice";
import { CarrierInvoiceState } from "../../generated-openapi-client/models/CarrierInvoiceState";
import { CurrencyCode } from "../../generated-openapi-client/models/CurrencyCode";
import { convertToCad } from "../../Helpers/convertToCad";
import { isBlank } from "../../Helpers/isNotBlank";
import { formatAsCurrency } from "../../Helpers/numberFormatting";
import { safeColumns } from "../../Helpers/safeColumns";
import { carrierPropertiesAnyService } from "../../Helpers/serviceProperties";
import Spacer from "../../Spacer";
import { CurrencySelector } from "../CurrencySelector";
import { ChallengeAllInvoicesMenuItem } from "./ChallengeAllInvoicesMenuItem";
import { CheckIfCarrierCreditNoteIsPaidMenuItem } from "./CheckIfCarrierCreditNoteIsPaidMenuItem";
import { CreateMondayMissingInvoiceAndEmailCarrierMenuItem } from "./CreateMondayMissingInvoiceAndEmailCarrierMenuItem";
import { DoNotFetchFurtherCarrierInvoicesMenuItem } from "./DoNotFetchFurtherCarrierInvoicesMenuItem";
import { IdentifyInvoiceAuditProblemsSection } from "./IdentifyInvoiceAuditProblemsSection";
import { MarkCarrierCreditNoteRefundRequestedMenuItem } from "./MarkCarrierCreditNoteRefundRequestedMenuItem";
import { RetryInvoiceApprovalMenuItem } from "./RetryInvoiceApprovalMenuItem";
import { SendOverchargeClaimEmailMenuItem } from "./SendOverchargeClaimEmailMenuItem";
import { TabProps } from "./TabProps";
import { ViewCarrierInvoiceButton } from "./ViewCarrierInvoiceButton";
import { VoidCarrierCreditNoteMenuItem } from "./VoidCarrierCreditNoteMenuItem";

const { Title } = Typography;

export function describeCarrierInvoiceState(
  carrierInvoiceState: CarrierInvoiceState
) {
  switch (carrierInvoiceState) {
    case CarrierInvoiceState.Issued:
      return `🤔 Issued`;
    case CarrierInvoiceState.Approved:
      return `👍 Approved`;
    case CarrierInvoiceState.PaymentPending:
      return `👌 Payment Pending`;
    case CarrierInvoiceState.PaymentConfirmed:
      return `✅ Payment Confirmed`;
    case CarrierInvoiceState.Voided:
      return `🌑 Voided`;
    default:
      return carrierInvoiceState;
  }
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      // Use a regex to remove data url part
      // @ts-ignore
      const base64String = reader.result
        // @ts-ignore
        .replace("data:", "")
        .replace(/^.+,/, "");

      // Logs wL2dvYWwgbW9yZ...
      resolve(base64String);
    };
    reader.readAsDataURL(blob);
  });
}

function AddCarrierInvoiceButton(props: TabProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const [currency, setCurrency] = useState<CurrencyCode | undefined>();
  const [base64, setBase64] = useState<string | undefined>();
  const [isAdditional, setIsAdditional] = useState(false);
  const [invoiceIdentifier, setInvoiceIdentifier] = useState(
    props.shipmentData.shipment.shipment.proNumber!!
  );
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  const [storing, setStoring] = useState(false);
  const [carrierIdentifier, setCarrierIdentifier] = useState(
    props.shipmentData.shipment.quote.carrierIdentifier!!
  );

  useEffect(
    function () {
      if (isModalVisible) {
        setAmount(0);
        setCurrency(undefined);
        setBase64(undefined);
        setIsAdditional(false);
      }
    },
    [isModalVisible]
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    console.log(`handleOk`);
    const carrierInvoiceApi = await createCarrierInvoiceApi();
    const shipmentId = props.shipmentData.shipment.shipment.shipmentId!!;

    if (currency === undefined) {
      message.warn("Missing currency");
      return;
    }

    if (base64 === undefined) {
      message.warn("No PDF provided");
      return;
    }

    if (invoiceIdentifier === undefined) {
      message.warn("No invoice identifier");
      return;
    }

    setStoring(true);

    try {
      console.log(`handleOk - call api`);

      const approvalType = await carrierInvoiceApi.addCarrierInvoice({
        shipmentId,
        carrierIdentifier,
        invoiceIdentifier,
        amount,
        currency,
        isAdditional,
        body: base64,
      });

      await props.onRefresh();
      setIsModalVisible(false);

      switch (approvalType) {
        case CarrierInvoiceApprovalType.Approved:
          message.success("Approved!");
          break;
        case CarrierInvoiceApprovalType.Duplicate:
          message.warning("Ignored. Duplicate");
          break;
        case CarrierInvoiceApprovalType.NotApprovedTooExpensive:
          message.error("Not Approved. Monday task created to track");
          break;
        case CarrierInvoiceApprovalType.Zero:
          message.warning("Ignored. Value is zero");
          break;
      }

      setStoring(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
      setStoring(false);
    }
    console.log(`handleOk - done`);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const nonVoidedCustomerInvoices =
    props.shipmentData.billingTab.customerInvoices.filter(function (ci) {
      return ci.customerInvoiceState !== CustomerInvoiceState.Voided;
    })!!;

  const totalPaidByCustomer = nonVoidedCustomerInvoices
    .map(function (ci) {
      let factor = 1;
      if (
        ci.invoiceType === CustomerInvoiceType.ShipmentRefund ||
        ci.invoiceType === CustomerInvoiceType.AdditionalChargeRefund
      ) {
        factor = -1;
      }

      return factor * convertToCad(ci.amount!!, ci.currency!!);
    })
    .reduce((a, b) => a + b, 0);

  function showAdditional() {
    const invoicesWithoutVoided =
      props.shipmentData.billingTab.carrierInvoices.filter(
        (ci) => ci.invoiceState !== CarrierInvoiceState.Voided
      );
    return invoicesWithoutVoided.length !== 0;
  }

  function onCopyQuotedPrice() {
    setAmount(
      parseFloat(
        props.shipmentData.shipment.quote.quotedPriceByCarrierOriginalCurrency!!.toFixed(
          2
        )
      )
    );
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Add Carrier Invoice"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add Carrier Invoice"
        confirmLoading={storing}
        destroyOnClose={true}
        width={800}
      >
        <HorizontalStack style={{ marginLeft: "-24px" }} spacing={24}>
          <StatisticText
            title={props.shipmentData._configuration.mainTrackingNumberName}
            value={props.shipmentData.shipment.shipment.proNumber}
          />
          <Statistic
            title="Quote"
            value={formatAsCurrency(
              props.shipmentData.shipment.quote
                .quotedPriceByCarrierOriginalCurrency
            )}
            suffix={props.shipmentData.shipment.quote.originalCurrency}
          />
          <Statistic
            title="Paid by customer"
            value={formatAsCurrency(totalPaidByCustomer)}
            suffix={CurrencyCode.Cad}
          />
        </HorizontalStack>
        <Spacer height={48} />
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item
            label={
              <Stack align="right">
                <div>Invoice Amount</div>
                <LinkButton onClick={onCopyQuotedPrice} style={{ margin: 0 }}>
                  Copy Quote Amount ($
                  {props.shipmentData.shipment.quote.quotedPriceByCarrierOriginalCurrency?.toFixed(
                    2
                  )}
                  )
                </LinkButton>
              </Stack>
            }
          >
            <InputNumber
              prefix="$"
              value={amount}
              onChange={setAmount}
              style={{ width: "150px" }}
            />
          </Form.Item>
          <Form.Item label="Invoice Currency">
            <CurrencySelector currency={currency} setCurrency={setCurrency} />
          </Form.Item>
          <Form.Item label="Carrier">
            <CarrierFilter
              carrierIdentifier={carrierIdentifier}
              allowClear={false}
              onFilter={function (e) {
                if (e !== undefined) {
                  setCarrierIdentifier(e);
                }
              }}
            />
          </Form.Item>
          <Form.Item label="Invoice PDF">
            {/* @ts-ignore */}
            <Dragger
              name="file"
              accept=".pdf"
              multiple={false}
              beforeUpload={async function (info) {
                const array = await info.arrayBuffer();
                const base64 = await blobToBase64(new Blob([array]));
                setBase64(
                  base64.replace("data:application/octet-stream;base64,", "")
                );
                return false;
              }}
              onChange={function () {}}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag invoice to upload (pdf only)
              </p>
            </Dragger>
          </Form.Item>
          <Form.Item
            label={
              <>
                Invoice Identifier <Spacer width={8} />
                <InfoBubble
                  overlay={`Typically this is the current pro number, but sometimes sometimes additional or reissued invoices will have a different number, or sometimes multiple pro numbers would belong to the same shipment, possibly from different carriers`}
                />
              </>
            }
          >
            <Input
              value={invoiceIdentifier}
              onChange={function (e) {
                setInvoiceIdentifier(e.target.value);
              }}
            />
          </Form.Item>
          {showAdditional() && (
            <Form.Item label="Is Additional Invoice?">
              <Radio.Group
                value={isAdditional}
                onChange={function (e) {
                  setIsAdditional(e.target.value);
                }}
              >
                <Radio.Button value={false}>Replacement </Radio.Button>
                <Radio.Button value={true}>Additional</Radio.Button>
              </Radio.Group>
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Button onClick={showModal}>Add Carrier Invoice</Button>
    </>
  );
}

function CarrierCreditNotesTable(props: TabProps) {
  const columns: DataTableColumn<CarrierCreditNote>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier!!}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "State",
      render: (o) => <div>{o.carrierCreditNoteState}</div>,
    },
    {
      title: "Refund Requested",
      render: (o) => <div>{o.refundRequested ? "✅" : "❌"}</div>,
    },
    {
      title: "Amount",
      render: (o) => <Dollar currency={o.currency}>{o.amount!!}</Dollar>,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow
          extrasMenu={
            <Menu>
              <UrlMenuItem
                title="View in Xero"
                disabled={isBlank(o.xeroCreditNoteId)}
                url={`https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=${o.xeroCreditNoteId}`}
              />
              <MenuDivider />
              <VoidCarrierCreditNoteMenuItem carrierCreditNote={o} {...props} />
              <MenuDivider />
              <SendOverchargeClaimEmailMenuItem
                carrierCreditNote={o}
                {...props}
              />
              <MenuDivider />
              <MarkCarrierCreditNoteRefundRequestedMenuItem
                carrierCreditNote={o}
                onRefresh={props.onRefresh}
              />
              <MenuDivider />
              <CheckIfCarrierCreditNoteIsPaidMenuItem
                carrierCreditNote={o}
                {...props}
              />
            </Menu>
          }
        >
          <></>
        </ButtonRow>
      ),
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.shipmentData.billingTab.carrierCreditNotes}
    />
  );
}

function CarrierInvoicesTable(props: TabProps) {
  const [selectedS3Keys, setSelectedS3Keys] = useState<string[]>([]);
  const carrierProperties: any = carrierPropertiesAnyService(
    props.shipmentData.shipment.quote.carrierIdentifier!!
  );
  const paymentProperties = carrierProperties.paymentProperties;
  const missingInvoiceStrategy = paymentProperties.missingInvoiceStrategy;

  function styleForVoided(o: CarrierInvoice) {
    return {
      color: o.invoiceState === CarrierInvoiceState.Voided ? "#ccc" : undefined,
    };
  }
  return (
    <>
      <HorizontalStack align="spread">
        <Title level={4}>
          Carrier Invoices{" "}
          <ButtonRow style={{ marginTop: "8px" }}>
            {props.shipmentData.shipment.shipment.challengeAllInvoices && (
              <Tag color="red">Challenge All Invoices</Tag>
            )}
            {props.shipmentData.billingTab.carrierInvoices.length === 0 && (
              <Tag color="purple">
                Missing Invoice Strategy :{" "}
                {missingInvoiceStrategy.numberOfDaysToConsiderOverdue} days from{" "}
                {missingInvoiceStrategy.comparisonDay}
                {missingInvoiceStrategy.shouldEmailCarrierToRequestInvoice
                  ? " (Will email)"
                  : " (Will not email)"}
              </Tag>
            )}
          </ButtonRow>
        </Title>

        <ButtonRow>
          <ViewPdfsSideBySideButton
            document1S3Key={selectedS3Keys[0]}
            document2S3Key={selectedS3Keys[1]}
            filenamePrefix="invoice"
            disabled={selectedS3Keys.length !== 2}
          />
        </ButtonRow>
      </HorizontalStack>

      <Spacer height={8} />
      <Table
        rowKey={function (ci) {
          return ci.carrierInvoiceId!!;
        }}
        rowSelection={{
          type: "checkbox",
          onChange: (
            _selectedRowKeys: React.Key[],
            _selectedRows: CarrierInvoice[]
          ) => {
            console.log(
              `selectedRowKeys: ${_selectedRowKeys}`,
              "selectedRows: ",
              _selectedRows
            );
            setSelectedS3Keys(
              _selectedRows.map(function (row) {
                return row.s3Key!!;
              })
            );
          },
        }}
        columns={safeColumns<CarrierInvoice>([
          {
            title: "New?",
            dataIndex: "new",
            key: "new",
            render: function (_, o) {
              if (
                !o.problemsIdentified &&
                props.shipmentData.shipment.shipment.invoiceAuditOpen &&
                o.invoiceState !== CarrierInvoiceState.Voided
              ) {
                return <Tag color={Colors.Blue}>⭐ New</Tag>;
              }
            },
          },
          {
            title: "Carrier",
            dataIndex: "carrierInvoiceId",
            key: "carrierInvoiceId",
            render: (carrierInvoiceId, o) => (
              <Stack align="left">
                <CarrierLogo
                  carrierIdentifier={o.carrierIdentifier}
                  brokeredCarrierIdentifier={undefined}
                  width={40}
                  height={30}
                />
                <div style={{ ...styleForVoided(o), fontSize: "11px" }}>
                  {o.invoiceIdentifier}
                </div>
              </Stack>
            ),
          },
          {
            title: "State",
            dataIndex: "invoiceState",
            key: "invoiceState",
            render: (invoiceState) => (
              <CarrierInvoiceStateTag state={invoiceState} />
            ),
          },
          {
            title: "Uploaded",
            dataIndex: "invoiceState",
            key: "invoiceState",
            render: (invoiceState, carrierInvoice) => (
              <div style={styleForVoided(carrierInvoice)}>
                <CreatedAt timestamp={carrierInvoice.createdAt!!} />
              </div>
            ),
          },
          {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (amount, o) => (
              <div style={styleForVoided(o)}>
                {formatAsCurrency(amount, o.currency)}
              </div>
            ),
          },
          {
            title: "Source",
            dataIndex: "source",
            key: "source",
            render: (source) => <CarrierInvoiceSourceTag source={source} />,
          },
          {
            title: "Approval Type",
            dataIndex: "approvalType",
            key: "approvalType",
            render: (approvalType) => (
              <CarrierInvoiceApprovalTypeTag approvalType={approvalType} />
            ),
          },
          {
            title: "Actions",
            dataIndex: "carrierInvoiceId",
            key: "actions",
            render: function (
              carrierInvoiceId: string,
              carrierInvoice: CarrierInvoice
            ) {
              console.log(`carrierInvoice`, { carrierInvoice });
              return (
                <ViewCarrierInvoiceButton
                  shipmentData={props.shipmentData}
                  carrierInvoice={carrierInvoice}
                  onRefresh={props.onRefresh}
                  onPatchShipmentData={props.onPatchShipmentData}
                />
              );
            },
          },
        ])}
        dataSource={props.shipmentData.billingTab.carrierInvoices}
      />
    </>
  );
}

export function CarrierInvoicesTab(props: TabProps) {
  function showInvoiceAuditProblems(): boolean {
    if (
      props.shipmentData.shipment.shipment.invoiceAuditProblems!!.length > 0
    ) {
      return true;
    }

    if (props.shipmentData.shipment.shipment.invoiceAuditOpen) {
      return true;
    }

    return false;
  }

  return (
    <>
      <UsedCouponsAlert coupons={props.shipmentData.billingTab.couponsUsed} />
      <div style={{ display: "flex", alignItems: "start" }}>
        <ButtonRow
          extrasMenu={
            <Menu>
              <ChallengeAllInvoicesMenuItem {...props} />
              <DoNotFetchFurtherCarrierInvoicesMenuItem {...props} />
              <RetryInvoiceApprovalMenuItem {...props} />
              <CreateMondayMissingInvoiceAndEmailCarrierMenuItem {...props} />
            </Menu>
          }
        >
          <AddCarrierInvoiceButton {...props} />
        </ButtonRow>
      </div>

      <Spacer height={32} />

      {showInvoiceAuditProblems() && (
        <>
          <IdentifyInvoiceAuditProblemsSection
            {...props}
            carrierIdentifier={
              props.shipmentData.shipment.quote.carrierIdentifier!!
            }
            carrierInvoice={undefined}
          />
          <Spacer height={32} />
        </>
      )}

      {props.shipmentData.billingTab.carrierCreditNotes.length > 0 && (
        <>
          <Title level={4}>Carrier Credit Notes</Title>
          <CarrierCreditNotesTable {...props} />
          <Spacer height={32} />
        </>
      )}

      <CarrierInvoicesTable {...props} />
    </>
  );
}
