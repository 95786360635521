import { Button, ButtonProps } from "antd";
import React from "react";
import styled from "styled-components/macro";

const isLightColor = (color: string): boolean => {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128;
};

interface StyledButtonProps extends ButtonProps {
  bgColor?: string;
}

const StyledButton = styled(Button)<StyledButtonProps>`
  &.ant-btn {
    background-color: ${(props) => props.bgColor || "gray"};
    color: ${(props) =>
      props.bgColor && isLightColor(props.bgColor) ? "#000" : "#fff"};
    border: none;

    &:hover,
    &:focus {
      background-color: ${(props) => props.bgColor || "gray"};
      color: ${(props) =>
        props.bgColor && isLightColor(props.bgColor) ? "#000" : "#fff"};
      opacity: 0.8;
    }

    &:active {
      background-color: ${(props) => props.bgColor || "gray"};
      color: ${(props) =>
        props.bgColor && isLightColor(props.bgColor) ? "#000" : "#fff"};
      opacity: 0.9;
    }
  }
`;

interface AdaptiveButtonProps extends ButtonProps {
  bgColor?: string;
}

const AdaptiveButton: React.FC<AdaptiveButtonProps> = ({
  bgColor,
  children,
  ...props
}) => {
  return (
    <StyledButton bgColor={bgColor} {...props}>
      {children}
    </StyledButton>
  );
};

export default AdaptiveButton;
