import { Tabs, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShipmentsApi } from "../Apis/Apis";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import PageTitle from "../Components/PageTitle";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import {
  CustomsDocsState,
  ViewShipmentDataForApollo,
} from "../generated-openapi-client";
import { AdditionalChargeState } from "../generated-openapi-client/models/AdditionalChargeState";
import { ShipmentMondayStatus } from "../generated-openapi-client/models/ShipmentMondayStatus";
import { CarrierInvoicesTab } from "./ViewShipmentScreenComponents/CarrierInvoicesTab";
import { ClaimsTab } from "./ViewShipmentScreenComponents/ClaimsTab";
import { ContactsTab } from "./ViewShipmentScreenComponents/ContactsTab";
import { CrossBorderTab } from "./ViewShipmentScreenComponents/CrossBorderTab";
import { CustomerInvoicesTab } from "./ViewShipmentScreenComponents/CustomerInvoicesTab";
import { DocumentsTab } from "./ViewShipmentScreenComponents/DocumentsTab";
import { InvoiceApprovalResolutionWidget } from "./ViewShipmentScreenComponents/InvoiceApprovalResolutionWidget";
import { ManagePickupTab } from "./ViewShipmentScreenComponents/ManagePickupTab";
import { ManageQuotesTab } from "./ViewShipmentScreenComponents/ManageQuotesTab";
import { ManageTrackingTab } from "./ViewShipmentScreenComponents/ManageTrackingTab";
import { MondayResolutionWidget } from "./ViewShipmentScreenComponents/MondayResolutionWidget";
import { NotesTab } from "./ViewShipmentScreenComponents/NotesTab";
import { TechnicalTab } from "./ViewShipmentScreenComponents/TechnicalTab";
import { ViewShipmentHeader } from "./ViewShipmentScreenComponents/ViewShipmentHeader";

const { TabPane } = Tabs;
export function ViewShipmentScreen() {
  const createShipmentsApi = useShipmentsApi();
  const [loading, setLoading] = useState<undefined | string>(undefined);
  const [shipmentData, setShipmentData] = useState<
    ViewShipmentDataForApollo | undefined
  >();

  const [shipmentMondayStatus, setShipmentMondayStatus] = useState<
    ShipmentMondayStatus | undefined
  >();

  const query = useQuery();
  const navigate = useNavigate();

  const shipmentId = query.shipmentId as string;
  console.log(`ViewShipmentScreen : ${shipmentId}`);
  const tab = query.tab as string;

  function outputForClaude() {
    if (shipmentData === undefined) {
      return;
    }

    const newNotesData = shipmentData.notesTab.notes.map(function (note) {
      const newNote = {
        createdAt: note.createdAt,
        message: note.message,
        author: note.author,
        category: note.category,
        body: note.body,
        headline: note.headline,
        emailFrom: note.emailFrom,
        emailTo: note.emailTo,
        emailSubject: note.emailSubject,
        emailBodyCleanedUp: note.emailBodyCleanedUp,
      };
      return newNote;
    });
    const o = { ...shipmentData, notesTab: { ...newNotesData } };
    console.log(`$$$$ claude data`, { o });
  }

  async function refresh(showLoading = false) {
    if (showLoading) {
      setLoading("Creating Shipments Api");
    }
    const shipmentsApi = await createShipmentsApi();
    if (showLoading) {
      setLoading("Get Shipment By Id");
    }
    const response = await shipmentsApi.getShipmentById({ shipmentId });
    if (showLoading) {
      setLoading("Processing Response");
    }
    setShipmentData(response);
    if (showLoading) {
      setLoading(undefined);
    }
    console.log("refreshed");

    outputForClaude();
  }

  async function onPatchShipmentData(_shipmentData: ViewShipmentDataForApollo) {
    setShipmentData(_shipmentData);
  }

  async function refreshMondayStatus() {
    const shipmentsApi = await createShipmentsApi();
    const response = await shipmentsApi.getShipmentMondayStatus({ shipmentId });
    setShipmentMondayStatus(response);
  }

  useEffect(
    function () {
      refresh(true);
      refreshMondayStatus();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shipmentId]
  );

  function callback(key: string) {
    console.log(key);

    navigate(`/view-shipment?shipmentId=${shipmentId}&tab=${key}`, {
      replace: true,
    });
  }

  function generateCarrierInvoicesTabTitle() {
    const moneyOut = shipmentData?.shipmentReport?.moneyOutCad;
    const numberCarrierInvoices =
      shipmentData?.shipmentReport?.numberCarrierInvoices;

    if (moneyOut === undefined) {
      return `Money Out`;
    }
    return `Money Out : $${moneyOut.toFixed(2)} (${numberCarrierInvoices})`;
  }

  function generateCrossBorderTabTitle() {
    if (
      shipmentData!!.shipment.shipment.customsDocsState ===
      CustomsDocsState.Missing
    ) {
      return "‼️ Cross Border";
    }
    return "Cross Border";
  }

  function generateCustomerInvoicesTabTitle() {
    // Show a warning if there are additional charges that need to be dealt with
    const additionalChargesRequiringAction =
      shipmentData!!.billingTab.additionalCharges.filter(function (ac) {
        return (
          ac.state === AdditionalChargeState.Proposed ||
          ac.state === AdditionalChargeState.Approved
        );
      });
    const showWarning = additionalChargesRequiringAction.length > 0;

    const suffix = showWarning ? (
      <>
        <Spacer width={4} />
        <Tooltip title="There are additional charges that need dealing with">
          ⚠️
        </Tooltip>
      </>
    ) : (
      <></>
    );

    function label() {
      const moneyIn = shipmentData?.shipmentReport?.moneyInCad;
      const numberCustomerInvoices =
        shipmentData?.shipmentReport?.numberCustomerInvoices;

      if (moneyIn === undefined) {
        return `Money In`;
      } else {
        return `Money In : $${moneyIn.toFixed(2)} (${numberCustomerInvoices})`;
      }
    }

    return (
      <HorizontalStack verticalAlign="middle">
        <div>{label()}</div>
        {suffix}
      </HorizontalStack>
    );
  }

  if (shipmentData === undefined || loading) {
    return <Loading message={loading} />;
  } else {
    const pickupAddress =
      shipmentData.shipment.shipment.pickupLocation?.address;
    const deliveryAddress =
      shipmentData.shipment.shipment.deliveryLocation?.address;
    const from = `${pickupAddress?.city}, ${pickupAddress?.stateOrProvinceCode}`;
    const to = `${deliveryAddress?.city}, ${deliveryAddress?.stateOrProvinceCode}`;
    const pageTitle = `📦 ${shipmentData.shipment.company.companyName} : ${from} to ${to}`;
    const isBooked = shipmentData.shipment.shipment.bookedAt !== undefined;
    const isCrossBorder =
      shipmentData.shipment.shipment.pickupLocation?.address?.countryCode !==
      shipmentData.shipment.shipment.deliveryLocation?.address?.countryCode;

    const carrierInvoicesTabTitle = generateCarrierInvoicesTabTitle();
    const customerInvoicesTabTitle = generateCustomerInvoicesTabTitle();
    const crossBorderTabTitle = generateCrossBorderTabTitle();
    return (
      <>
        <PageTitle>{pageTitle}</PageTitle>
        <ViewShipmentHeader
          shipmentData={shipmentData}
          onRefresh={refresh}
          onPatchShipmentData={onPatchShipmentData}
          widget={
            <>
              <MondayResolutionWidget
                shipmentData={shipmentData}
                shipmentMondayStatus={shipmentMondayStatus}
                onRefresh={refreshMondayStatus}
              />
              <InvoiceApprovalResolutionWidget
                shipmentData={shipmentData}
                onRefresh={refresh}
              />
            </>
          }
        >
          <Tabs
            defaultActiveKey={tab}
            onChange={callback}
            style={{ width: "100%" }}
          >
            <TabPane tab="Key Info" key="keyinfo">
              <ManagePickupTab
                shipmentData={shipmentData}
                onRefresh={refresh}
                onPatchShipmentData={onPatchShipmentData}
              />
            </TabPane>
            <TabPane tab="Quotes" key="quotes">
              <ManageQuotesTab
                shipmentData={shipmentData}
                onRefresh={refresh}
                onPatchShipmentData={onPatchShipmentData}
              />
            </TabPane>
            {isBooked && (
              <TabPane tab="Tracking" key="tracking">
                <ManageTrackingTab
                  shipmentData={shipmentData}
                  onRefresh={refresh}
                  onPatchShipmentData={onPatchShipmentData}
                />
              </TabPane>
            )}

            {isBooked && (
              <TabPane tab={customerInvoicesTabTitle} key="customerinvoices">
                <CustomerInvoicesTab
                  shipmentData={shipmentData}
                  onRefresh={refresh}
                  onPatchShipmentData={onPatchShipmentData}
                />
              </TabPane>
            )}
            {isBooked && (
              <TabPane tab={carrierInvoicesTabTitle} key="carrierinvoices">
                <CarrierInvoicesTab
                  shipmentData={shipmentData}
                  onRefresh={refresh}
                  onPatchShipmentData={onPatchShipmentData}
                />
              </TabPane>
            )}

            <TabPane tab="Notes" key="notes">
              <NotesTab
                shipmentData={shipmentData}
                onRefresh={refresh}
                onPatchShipmentData={onPatchShipmentData}
              />
            </TabPane>
            <TabPane tab="Contacts" key="contacts">
              <ContactsTab
                shipmentData={shipmentData}
                onRefresh={refresh}
                onPatchShipmentData={onPatchShipmentData}
              />
            </TabPane>
            {isCrossBorder && (
              <TabPane tab={crossBorderTabTitle} key="crossborder">
                <CrossBorderTab
                  shipmentData={shipmentData}
                  onRefresh={refresh}
                  onPatchShipmentData={onPatchShipmentData}
                />
              </TabPane>
            )}
            {isBooked && (
              <TabPane tab="Documents" key="documents">
                <DocumentsTab
                  shipmentData={shipmentData}
                  onRefresh={refresh}
                  onPatchShipmentData={onPatchShipmentData}
                />
              </TabPane>
            )}
            <TabPane tab="Technical" key="technical">
              <TechnicalTab
                shipmentData={shipmentData}
                onRefresh={refresh}
                onPatchShipmentData={onPatchShipmentData}
              />
            </TabPane>
            <TabPane tab="Claims" key="claims">
              <ClaimsTab
                shipmentData={shipmentData}
                onRefresh={refresh}
                onPatchShipmentData={onPatchShipmentData}
              />
            </TabPane>
          </Tabs>
        </ViewShipmentHeader>
      </>
    );
  }
}
