import { Select } from "antd";
import CarriersData from "../Fixtures/CarriersData.json";

interface ServiceTypeFilterProps {
  carrierIdentifier: string;
  serviceIdentifier: string | undefined;
  setServiceIdentifier: (_: string | undefined) => void;
  disabled?: boolean;
  allowClear?: boolean;
}
export function ServiceTypeFilter(props: ServiceTypeFilterProps) {
  const services = CarriersData.filter(
    (carrier) => carrier.carrierIdentifier === props.carrierIdentifier
  );

  return (
    <Select
      allowClear={props.allowClear}
      value={props.serviceIdentifier}
      onChange={props.setServiceIdentifier}
      disabled={props.disabled}
      style={{ width: "200px" }}
      placeholder="Any service"
    >
      {services.map(function (s) {
        return (
          <Select.Option value={s.serviceIdentifier}>
            {s.serviceIdentifier}
          </Select.Option>
        );
      })}
    </Select>
  );
}
